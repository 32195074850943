<template>
  <PageTemplate>
		<Section>
			<Stack>
				<Images height="500px" width="25%" image="theme-sd/home2.jpg" />
				<Images height="500px" width="25%" image="theme-sd/home1.jpg" />
				<Images height="500px" width="25%" image="theme-sd/home4.jpg" />
				<Images height="500px" width="25%" image="theme-sd/home3.jpg" />
			</Stack>
			<Card size="xlarge">
				<CardSection>
					<Stack direction="column" space="3">
						<Heading size="1" align="center">exeptional beauty starts here</Heading>
						<Heading size="4" align="center" color="tertiary" uppercase>Your look is so unique, let's treat it that way.</Heading>
						<Paragraph align="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Paragraph>
						<Button label="Our treatments" color="primary" size="large" url="/sedona/treatments" />
					</Stack>
				</CardSection>
			</Card>
			<Card color="secondary" size="large">
				<CardSection>
					<Card type="base" size="xsmall" overlap>
						<CardSection>
							<Grid>
								<GridContainer size="12 6@md 4@lg">
									<Stack direction="column">
										<Images height="300px" width="100%" image="theme-sd/home6.jpg" />
										<Button label="The Blog" color="primary" type="outline" url="/sedona/blog" overlap />
									</Stack>
								</GridContainer>
								<GridContainer size="12 6@md 4@lg">
									<Stack direction="column">
										<Images height="300px" width="100%" image="theme-sd/home5.jpg" />
										<Button label="The Services" color="primary" type="outline" url="/sedona/services" overlap />
									</Stack>
								</GridContainer>
								<GridContainer size="12 4@lg">
									<Stack direction="column">
										<Images height="300px" width="100%" image="theme-sd/home7.jpg" />
										<Button label="Say Hello" color="primary" type="outline" url="/sedona/contact" overlap />
									</Stack>
								</GridContainer>
							</Grid>
						</CardSection>
					</Card>
				</CardSection>
			</Card>
			<Card color="dark" size="large">
				<CardSection>
					<Stack direction="column" space="3">
						<Heading size="2" align="center" whiteout>"I have been a loyal Sedona patron for years… all of the massage therapists here are wonderful. It is so rare to find a place where the level of service is so consistently good but Sedona is such a place!”</Heading>
						<Heading size="2" align="center" whiteout>- Wendi B.</Heading>
					</Stack>
				</CardSection>
			</Card>
			<Card size="large">
				<CardSection>
					<Stack direction="column" space="3" align="left">
						<Heading size="4" align="center">GET TO KNOW US</Heading>
						<Grid>
							<GridContainer size="12 7@md">
								<Images height="600px" width="120%" image="theme-sd/home8.jpg" />
							</GridContainer>
							<GridContainer size="12 5@md" align="middle">
									<Card color="primary">
										<CardSection>
											<Stack direction="column" align="left" space="2">
												<Heading size="3" whiteout>creating glowing &amp;<br/> youthful transformations</Heading>
												<Paragraph whiteout>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Paragraph>
												<Button label="Learn more" color="tertiary" url="/sedona/about" />
											</Stack>
										</CardSection>
									</Card>
							</GridContainer>
						</Grid>
					</Stack>
				</CardSection>
			</Card>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/sedona/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
